export const STATES = [
  {
    name: 'Select State',
    tax: 9.025, // Default tax rate
  },
  {
    name: 'Minnesota',
    tax: 9.025,
  },
  {
    name: 'Nebraska',
    tax: 7,
  },
];

import React, { useEffect, useState, useContext } from 'react'
import { QuoteContext } from '..'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ProductsList from './ProductsList'

export function ProductsContainer({ PRODUCTS, clearOptionsClicked }) {
  const { options, active } = useContext(QuoteContext)
  // Not using PRODUCTS directly because it doesn't get updated when products get selected or un-selected
  const [productsGroups, setProductsGroups] = useState(PRODUCTS)


  const [activeKey, setActiveKey] = useState(0)

  const ACTIVE_OPTION_ITEMS = options[active.optionKey].items

  useEffect(() => {
    // This will get the products updated / Usefull to check for the "selected" property
    const productsGroupsClone = [...productsGroups]
    setProductsGroups(productsGroupsClone)
  }, [active.optionKey, ACTIVE_OPTION_ITEMS])

  useEffect(() => {
    if (clearOptionsClicked && activeKey !== 0) setActiveKey(0)
  }, [clearOptionsClicked])

  return (
    <section className="tabs-container px-0">
      <Tabs
        id="products-tabs"
        activeKey={activeKey}
        transition={false}
        onSelect={(k) => setActiveKey(k)}
        className={`products-tabs justify-content-start padding-x flex-nowrap pe-0`}
      >
        {
          productsGroups.map((productsGroup, x) => (
            <Tab key={x} eventKey={x} title={productsGroup.name}>
              <ProductsList
                productsGroup={productsGroup}
              />
            </Tab>
          ))
        }
      </Tabs>
    </section>
  )
}

export default ProductsContainer
export const updateSelections = (productsGroups, activeOptionItems) => {
  // 1. Unselect all items initially
  productsGroups.forEach(productsGroup => productsGroup.products.forEach(product => product.selected = false))
  // 2. Loop through the items in the active option array
  activeOptionItems.forEach(item => {
    // 3. Find the index of the corresponding group in the productsGroups array
    const productsGroupIndex = productsGroups.findIndex(productsGroup => productsGroup.id === item.productsGroupId)
    // 4. Find the child product as well
    const foundProduct = productsGroups[productsGroupIndex].products.find(product => product.id === item.id)
    // 5. Set "selected" to be true
    foundProduct.selected = true
  })
}
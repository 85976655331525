export const initialOptionValues = {
  monthlyPayment: [],
  downpayment: '',
  totalOriginalCost: 0,
  finalTotalCost: 0,
  totalSavings: 0,
  remainingAmount: 0,
  discount: 0,
  items: [],
  taxBeforeDiscount: 0,
  taxAfterDiscount: 0
}
import React, { useEffect, useState, useRef } from 'react';
import {
  SelctionsSection,
  ProductsContainer,
  OptionsContainer,
  QuoteController,
  QuoteActionsContainer,
  PrintHeader,
  Disclaimer,
} from '.';
import { PRODUCTS } from '../constant/products';
import { STATES } from '../constant/states';
import { DEFAULT_ACTIVE } from '../constant/defaultActive';
import { updateSelections, getDefaultOptionsObj } from '../utils';
import Footer from './Footer';
import useDates from '../hooks/useDates';

export const QuoteContext = React.createContext();

export function QuoteManager() {
  // 3 options to choose from
  const [options, setOptions] = useState(getDefaultOptionsObj());

  // Keep all the active actions in one state object
  const [active, setActive] = useState(DEFAULT_ACTIVE);

  const printRef = useRef(null);

  // Clear Options Button
  const [clearOptionsClicked, setClearOptionsClicked] = useState(false);
  const handleClearOptions = () => {
    if (localStorage.getItem('AthensStateName') !== null) {
      DEFAULT_ACTIVE.state = STATES.find(
        (state) => state.name === localStorage.getItem('AthensStateName')
      );
    }
    setOptions(getDefaultOptionsObj());
    setActive(DEFAULT_ACTIVE);
    setClearOptionsClicked(true);
    setTimeout(() => {
      setClearOptionsClicked(false);
    }, 500);
  };

  // Updating Selections in the ProductsContainer happen here
  // When switching between Options OR
  // When a product is added or removed in the active Option
  const activeOptionItems = options[active.optionKey].items;
  useEffect(() => {
    updateSelections(PRODUCTS, activeOptionItems);
  }, [active.optionKey, activeOptionItems]);

  // Context Values to be passed into nested components
  const contextValue = {
    options,
    setOptions,
    active,
    setActive,
    handleClearOptions,
  };

  const dates = useDates();

  return (
    <main className='container-xxl athens-bg-white'>
      <div className='row'>
        <QuoteContext.Provider value={contextValue}>
          <QuoteController />
          <ProductsContainer {...{ PRODUCTS, clearOptionsClicked }} />
          <div id='print-container' className='px-0' ref={printRef}>
            <PrintHeader active={active} />
            <SelctionsSection />
            <OptionsContainer options={options} />
            <div className='container-fluid print-display'>
              <Disclaimer dates={dates} textColor='navy' />
            </div>
          </div>
          <QuoteActionsContainer {...{ options, printRef, dates }} />
        </QuoteContext.Provider>
      </div>
      <Footer />
    </main>
  );
}

export default QuoteManager;

import React, { useContext } from 'react';
import { QuoteContext } from '..';
import { LENDERS } from '../../constant/lenders';
import { DISCOUNTS } from '../../constant/discounts';
import { STATES } from '../../constant/states';

function ProductsList({ productsGroup }) {
  const { options, setOptions, active } = useContext(QuoteContext);

  const handleClick = (product) => {
    if (
      active.state.name === STATES[0] ||
      active.lender.name === LENDERS[0].name
    )
      return;
    // Important: Notice here we're only updating the active option.items array
    // Setting the product to be (selected or unselected) doesn't actually happen here
    const x = active.optionKey;
    // If the product is selected
    if (product.selected && product.selected === true) {
      setOptions((prev) => ({
        ...prev,
        [x]: {
          ...options[x],
          items: [...options[x].items.filter((item) => item.id !== product.id)],
        },
      }));
    }
    // else if (options[x].items.length > 0 && options[x].items[0].id === 'fue-max-grafts') return // Don't add more products if a MaxGraft is selected
    else {
      // If the product is NOT selected
      const productClone = { ...product }; // To avoid altering the original area object
      productClone.productsGroupId = productsGroup.id; // Group ID: this is usefull in other components
      if (productClone.id.includes('bundle')) {
        productClone.discount = DISCOUNTS.find(
          (discount) => discount.label === 'Bundle'
        );
      } else {
        productClone.discount = DISCOUNTS[0];
      }

      // if FUE 1000 Grafts is already selected and now you want to add No Shave 1000 Grafts for instance,
      // The new item should replace the existing one in the active Option
      const sameGraftsAmountFound = options[x].items.some(
        (item) => item.label === productClone.label
      );
      if (sameGraftsAmountFound) {
        setOptions((prev) => ({
          ...prev,
          [x]: {
            ...options[x],
            items: [
              ...options[x].items.filter(
                (item) => item.label !== productClone.label
              ),
              productClone,
            ],
          },
        }));
      } else {
        setOptions((prev) => ({
          ...prev,
          [x]: {
            ...options[x],
            items: [...options[x].items, productClone],
          },
        }));
      }
    }
  };

  return (
    <ul id={productsGroup.id} className='row flex-wrap padding-x g-3 my-4'>
      {productsGroup.products.map((product) => (
        <li key={product.id} className='col-auto'>
          <button
            type='button'
            className={`product-item rounded px-3  ${
              product.selected && product.selected === true ? 'selected' : ''
            }`}
            onClick={() => handleClick(product)}
          >
            {product.label}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default ProductsList;

// const isMaxGraft = productClone.id === 'fue-max-grafts'

// if (isMaxGraft) {
//   // If the active Option has items && it is NOT the last Option -> Move to the next Option
//   if (options[x].items.length > 0 && x < Object.keys(options).length) {
//     let activeKey = x + 1
//     setOptions(prev => ({
//       ...prev,
//       [activeKey]: {
//         ...options[activeKey],
//         items: [productClone]
//       }
//     }))
//     setActive(prev => ({ ...prev, optionKey: (activeKey) }))
//   } else { // If the active Option does NOT have items OR it's the last Option, REPLACE current items
//     setOptions(prev => ({
//       ...prev,
//       [x]: {
//         ...options[x],
//         items: [productClone]
//       }
//     }))
//   }
// }

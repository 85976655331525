import { calculateSum, calculateAPRMonthlyPayment } from '.';

export const updateOptionProps = (option, active) => {
  const { financePlan, minRate, maxRate } = active.lender;
  const convertToNumber = (str) => Number(str.replaceAll(',', ''));

  // If no discount is applied // Default
  // NOTE: Some items are not fully taxable. Only part of it is (Like the MaxGraft and Laser Cap Bundle)
  const taxBeforeDiscountArr = option.items.map((item) =>
    item.taxable
      ? (item.taxable > 0 ? item.taxable : item.price) *
        (active.state.tax / 100)
      : 0
  );
  option.taxBeforeDiscount = calculateSum(taxBeforeDiscountArr);
  // Get the sum of all discounts values
  option.discount = calculateSum(
    option.items.map((item) => item.discount.value)
  );
  // If a discount is applied
  const taxAfterDiscountArr = option.items.map((item) =>
    // NOTE: if the item is partially taxable, Do NOT subtract the item discount value
    // taxable: it could be 'all' or a number
    item.taxable
      ? ((item.taxable > 0 ? item.taxable : item.price) -
          (item.taxable > 0 ? 0 : +item.discount.value)) *
        (active.state.tax / 100)
      : 0
  );
  option.taxAfterDiscount = calculateSum(taxAfterDiscountArr);
  // Get the total original price of all items
  option.totalOriginalCost = calculateSum(
    option.items.map((item) => item.price)
  );

  // Total Original Cost + Tax before discount (if any) // Important to calculate finalTotalCost
  const costPlusTaxBeforeDiscount =
    option.taxBeforeDiscount > 0
      ? option.totalOriginalCost + option.taxBeforeDiscount
      : option.totalOriginalCost;

  option.finalTotalCost = costPlusTaxBeforeDiscount - option.discount;

  // When a discount is applied
  if (option.taxAfterDiscount < option.taxBeforeDiscount) {
    // This means there's a discount applied
    // 1. Subtract the tax before discount from finalTotalCost
    option.finalTotalCost -= option.taxBeforeDiscount;
    // 2. Add the tax after discount to finalTotalCost
    option.finalTotalCost += option.taxAfterDiscount;
    // Otherwise, finalTotalCost will have both tax before and after discount added
  }

  option.remainingAmount =
    option.finalTotalCost - convertToNumber(active.downpayment);
  option.downpayment = convertToNumber(active.downpayment); // This is for Send EQuote
  option.financePlan = financePlan; // This is for Send EQuote

  option.monthlyPayment =
    minRate > 0 || maxRate > 0
      ? [
          calculateAPRMonthlyPayment(
            option.remainingAmount,
            minRate,
            financePlan
          ),
          calculateAPRMonthlyPayment(
            option.remainingAmount,
            maxRate,
            financePlan
          ),
        ]
      : [option.remainingAmount / financePlan];
};

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import QuoteManager from './QuoteManager';
import ATHENS_LOGO from '../assets/Athens-Logo.png';

export function LockScreen() {
  const [passcode, setPasscode] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (passcode.length === 4) {
      setLoading(true);
      axios
        .post(`/.netlify/functions/verifyPassCode?passcode=${passcode}`)
        .then((res) => {
          setIsValid(res.data.valid);
          if (!res.data.valid) {
            setError(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setError('Something went wrong :(');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError('');
      setIsValid(null);
      setLoading(false);
    }
  }, [passcode]);

  return (
    <section id='quote-app' className='App'>
      {isValid ? (
        <QuoteManager />
      ) : (
        <div className='container my-5 p-2 py-sm-5'>
          <div className='row d-flex justify-content-center align-items-center pt-3'>
            <div className='col'>
              <Form
                className='card border-0 shadow-sm py-5 w-100 mx-auto'
                style={{ maxWidth: '500px' }}
              >
                <figure className='text-center'>
                  <img
                    src={ATHENS_LOGO}
                    alt='Milan Logo Blue'
                    style={{ maxWidth: '140px' }}
                  />
                </figure>
                <h1 className='h4 px-3 text-center athens-text-primary fw-bold'>
                  Quote Calculator
                </h1>
                {loading ? (
                  <h5 className='fw-normal my-3 text-center athens-text-primary'>
                    Verifying...
                  </h5>
                ) : (
                  <>
                    <h5 className='fw-normal my-3 text-center athens-text-primary'>
                      Enter the Passcode
                    </h5>
                    <Form.Group
                      className='form-outline text-center mx-auto'
                      controlId='password'
                      style={{ maxWidth: '140px' }}
                    >
                      <Form.Control
                        className={`form-control-lg text-center ${
                          error ? 'border-danger text-danger mb-2' : 'mb-4'
                        }`}
                        type='password'
                        placeholder='****'
                        maxLength='4'
                        value={passcode}
                        onChange={(e) => setPasscode(e.target.value)}
                      />
                      {error && (
                        <small className='text-danger'>Invalid Passcode</small>
                      )}
                    </Form.Group>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default LockScreen;

import React from 'react'
import Card from 'react-bootstrap/Card'
import { USCurrentStyle, USCurrentStyleDecimal } from '../../utils'

function OptionOutputsTop({ option, active }) {
  return (
    <Card.Body className="py-2">
      {
        (option.monthlyPayment[0] > 0 && option.monthlyPayment[1] > 0 && option.monthlyPayment[0] !== option.monthlyPayment[1]) ?
          <h3 className="text-center display-6 athens-text-blue mb-0">
            {USCurrentStyle(option.monthlyPayment[0])}
            <span className="mx-2">-</span>
            {USCurrentStyle(option.monthlyPayment[1])}
          </h3>
          :
          <h3 className="text-center display-5 athens-text-blue mb-0">
            {USCurrentStyleDecimal(option.monthlyPayment[0])}
          </h3>
      }
      {
        active.lender.financePlan &&
        <p className="text-center athens-text-light-navy"><small>Per month for <strong>{active.lender.financePlan} months</strong></small></p>
      }
    </Card.Body>
  )
}

export default OptionOutputsTop
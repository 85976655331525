import React from 'react';
import Card from 'react-bootstrap/Card';
import { USCurrentStyle } from '../../utils';

function OptionOutputsBottom({ option, active }) {
  return (
    <Card.Body className='py-2'>
      {option.items.length > 0 && (
        <>
          {option.totalOriginalCost > option.finalTotalCost && (
            <p className='athens-text-navy mb-2'>
              <span>Original Price: </span>
              <span className='text-decoration-line-through athens-text-navy'>
                {USCurrentStyle(option.totalOriginalCost)}
              </span>
            </p>
          )}
          <p className='athens-text-navy mb-2'>
            <span>Your Price: </span>
            <span className='athens-text-navy'>
              {USCurrentStyle(option.finalTotalCost)}
            </span>
          </p>
          {active.downpayment !== '' && (
            <>
              <p
                className={`${
                  option.remainingAmount < 0
                    ? 'text-danger'
                    : 'athens-text-navy'
                }  mb-2`}
              >
                <span>Down Payment:</span> $<span>{active.downpayment}</span>
              </p>
              <p
                className={`${
                  option.remainingAmount < 0
                    ? 'text-danger'
                    : 'athens-text-navy'
                }  mb-2`}
              >
                <span>Total Financed:</span>{' '}
                <span>{USCurrentStyle(option.remainingAmount)}</span>
              </p>
            </>
          )}
          {option.discount > 0 && (
            <p className='athens-text-primary fw-bold mb-2 fs-5'>
              Total Savings: {USCurrentStyle(option.discount)}
            </p>
          )}
          {option.taxAfterDiscount > 0 && (
            <p className='athens-text-navy mb-0'>
              <small>
                <small>
                  <sup className='athens-text-primary fs-6 top-0'>*</sup>{' '}
                  {active.state.tax}% Sales Tax Applied at Check Out for
                  Products
                </small>
              </small>
              {/* <small className="athens-text-navy">{USCurrentStyleDecimal(option.taxAfterDiscount)}</small> */}
            </p>
          )}
        </>
      )}
    </Card.Body>
  );
}

export default OptionOutputsBottom;

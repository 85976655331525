import React, { useContext } from 'react'
import { QuoteContext } from '.'
import ATHENS_LOGO from '../assets/Athens-Logo-Wide.png'

export function QuoteController() {
  const { handleClearOptions } = useContext(QuoteContext)

  return (
    <header className={`py-4 padding-x athens-bg-navy`}>
      <nav className="container-fluid h-100 px-0">
        <div className="row h-100 gy-4 justify-content-md-between align-items-center">
          <div className="col-12 col-md-auto text-center">
            <img className="logo" src={ATHENS_LOGO} alt="Athens Hair Transplants Logo" />
          </div>
          <div className="col-12 col-md-auto">
            <ul className="row gx-2 gx-sm-3 gy-3 justify-content-center justify-content-md-end mb-0">
              <li className="col-auto">
                <button type="button" onClick={handleClearOptions} className="rounded px-3 athens-green-btn">Clear Options</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default QuoteController
import React, { useState, useRef } from 'react'
import SendEquote from './SendEquote'
import Disclaimer from '../Disclaimer'
import ReactToPrint from 'react-to-print'

export function QuoteActionsContainer({ options, printRef, dates }) {
  const [displayContent, setDisplayContent] = useState(null)
  const containerRef = useRef(null)
  const handleClick = action => {
    setDisplayContent(action)
    setTimeout(() => {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 150)
  }
  return (
    <section id="quote-actions" className="mt-4 px-0">
      <div className="container-fluid athens-bg-navy py-5">
        <Disclaimer dates={dates} textColor="white" />
        {
          Object.keys(options).some(key => options[key].items.length > 0) &&
          <div className="row gy-3 justify-content-center pt-4">
            <div className="col-6 col-md-auto">
              <ReactToPrint
                trigger={() => <button type='button' className={`athens-btn quote-action-btn w-100`}>Print Quote</button>}
                content={() => printRef.current}
                onBeforePrint={() => setDisplayContent('Print')}
              />
            </div>
            <div className="col-6 col-md-auto">
              <button type="button" className={`athens-btn quote-action-btn w-100 ${displayContent === 'EQuote' ? 'active' : ''}`} onClick={() => handleClick('EQuote')}>Email Quote</button>
            </div>
          </div>
        }
      </div>
      {
        Object.keys(options).some(key => options[key].items.length > 0) &&
        <div ref={containerRef} className="container-fluid">
          {
            displayContent === 'EQuote' && <SendEquote {...{ options, setDisplayContent }} />
          }
        </div>
      }
    </section>
  )
}

export default QuoteActionsContainer
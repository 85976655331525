import React, { useContext, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import { QuoteContext } from '..'
import OptionOutputsTop from './OptionOutputsTop'
import OptionOutputsBottom from './OptionOutputsBottom'
import ProductRow from './ProductRow'
import { initialOptionValues } from '../../constant/initialOptionValues'
import { updateOptionProps } from '../../utils'

export function OptionWrapper({ option, optionKey }) {
  const { options, setOptions, active, setActive } = useContext(QuoteContext)

  const optionClone = { ...option }
  if (optionClone.items.length > 0) updateOptionProps(optionClone, active)
  // This useEffect will update  Option props globally when:
  // Whenever the option final cost is affected. Which means, it will update for most actions
  // It will also update when lender or the downpayment is updated 
  useEffect(() => {
    setOptions(prev => ({ ...prev, [optionKey]: optionClone }))
  }, [optionClone.finalTotalCost, active.lender, active.downpayment])

  const isDisabled = (optionKey > 1 && optionClone.items.length === 0 && options[optionKey - 1].items.length === 0)
  const onClickOption = key => {
    if (isDisabled) return
    setActive(prev => ({ ...prev, optionKey: +key }))
  }

  const clearOption = key => {
    setOptions(prev => ({ ...prev, [key]: initialOptionValues }))
    if (options[1].items.length === 0) { // if Option 1 is empty -> make it active
      setActive(prev => ({ ...prev, optionKey: 1 }))
    } else { // else: make the cleared Option active
      setActive(prev => ({ ...prev, optionKey: +key }))
    }
    // Finally: If the previous Option is empty -> make it active
    if (key > 1 && options[key - 1].items.length === 0) {
      setActive(prev => ({ ...prev, optionKey: key - 1 }))
    }
  }

  return (
    <div className="col-md-6 col-lg-4 col-print">
      <div className='h-100'>
        <Card className={`option-card card-border ${isDisabled ? 'disabled' : ''} ${+optionKey === +active.optionKey ? 'active' : ''}`}>
          <div className={`output text-center pointer pt-4 rounded-top athens-bg-light-blue`} onClick={() => onClickOption(optionKey)}>
            <h2 className="athens-text-primary d-h text-uppercase mb-0">Option {optionKey}</h2>
            {
              optionClone.items.length > 0 ?
                <OptionOutputsTop option={optionClone} active={active} />
                :
                <Card.Body className="py-2">
                  <h3 className="text-center athens-text-primary display-6">
                    $ _ _ _ _
                  </h3>
                  {
                    active.lender.financePlan &&
                    <p className="athens-text-light-navy text-center"><small>Per month for <strong>{active.lender.financePlan} months</strong></small></p>
                  }
                </Card.Body>
            }
          </div>
          <Card.Body id="option-items" className="p-0 rounded-bottom">
            {optionClone.items.length > 0 && optionClone.items.map((product, x) => <ProductRow key={x} option={optionClone} {...{ optionKey, product }} />)}
          </Card.Body>
          <div className={`output text-center pointer py-3 rounded-bottom athens-bg-light-blue`} onClick={() => onClickOption(optionKey)}>
            <OptionOutputsBottom option={optionClone} active={active} />
          </div>
        </Card>
        <div className="text-center mt-4 print-hide">
          <button
            type="button"
            className="athens-btn athens-primary-btn"
            onClick={() => clearOption(optionKey)}
            disabled={optionClone.items.length === 0}>
            Clear Option {optionKey}
          </button>
        </div>
      </div>
    </div>
  )
}

export default OptionWrapper
export const USCurrentStyle = amount => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(amount)

// export const USCurrentStyleDecimal = amount => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)

export const USCurrentStyleDecimal = (amount) => {
  const decimalCount = (amount % 1 === 0) ? 0 : 2;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  });

  return formatter.format(amount);
};
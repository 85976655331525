export const LENDERS = [
  {
    name: 'Select Lender',
    minRate: 0,
    maxRate: 0,
    financePlan: undefined,
  },
  {
    name: 'Ally 36',
    minRate: 0,
    maxRate: 0,
    financePlan: 36,
  },
  {
    name: 'Ally 48',
    minRate: 0.0499, // 4.99%
    maxRate: 0.0999, // 9.99%
    financePlan: 48,
  },
  {
    name: 'Ally 60',
    minRate: 0.0499, // 4.99%
    maxRate: 0.0999, // 9.99%
    financePlan: 60,
  },
  {
    name: 'Athens 24',
    minRate: 0,
    maxRate: 0,
    financePlan: 24,
  },
]
import React from 'react'

function Footer() {
  return (
    <footer className="py-4">
      <p className="text-center mb-0"><small>Copyright © {new Date().getFullYear()} Athens Hair Transplants. <br className="d-sm-none" /> All rights reserved.</small></p>
    </footer>
  )
}

export default Footer
import React from 'react'
import ATHENS_LOGO from '../assets/Athens-Logo.png'
import { LENDERS } from '../constant/lenders'

export function PrintHeader({ active }) {
  return (
    <section className="container-fluid pt-4 print-display">
      <div className="row justify-content-between align-items-center">
        <div className="col-auto">
          <h1 className="athens-text-primary mb-0 h4">Custom Quote(s)</h1>
        </div>
        <div className="col-auto">
          <figure className="text-center mb-0">
            <img src={ATHENS_LOGO} alt="Milan Logo Blue" style={{ maxWidth: '140px' }} />
          </figure>
        </div>
      </div>
      <div className="row gx-1 mt-3">
        {
          active.lender.name !== LENDERS[0].name &&
          <>
            <div className="col-auto">
              <p><strong>Financing:</strong> {active.lender.financePlan} Month</p>
            </div>
            <div className="col-auto">
              <p>| <strong>Lender:</strong> {active.lender.name}</p>
            </div>
          </>
        }
      </div>
    </section>
  )
}

export default PrintHeader
export const PRODUCTS = [
  {
    id: 'fue-standard',
    name: 'FUE Standard',
    products: [
      {
        id: 'fue-500-grafts',
        label: '500 Grafts',
        price: 6895,
      },
      {
        id: 'fue-750-grafts',
        label: '750 Grafts',
        price: 9478,
      },
      {
        id: 'fue-1000-grafts',
        label: '1000 Grafts',
        price: 12639,
      },
      {
        id: 'fue-1250-grafts',
        label: '1250 Grafts',
        price: 14073,
      },
      {
        id: 'fue-1500-grafts',
        label: '1500 Grafts',
        price: 14933,
      },
      {
        id: 'fue-1750-grafts',
        label: '1750 Grafts',
        price: 16244,
      },
      {
        id: 'fue-max-grafts',
        label: 'MaxGraft',
        price: 16499,
      },
    ],
  },
  {
    id: 'no-shave',
    name: 'No Shave',
    products: [
      {
        id: 'no-shave-500',
        label: '500 Grafts',
        price: 7395,
      },
      {
        id: 'no-shave-750',
        label: '750 Grafts',
        price: 9978,
      },
      {
        id: 'no-shave-1000',
        label: '1000 Grafts',
        price: 13389,
      },
      {
        id: 'no-shave-1250',
        label: '1250 Grafts',
        price: 14823,
      },
      {
        id: 'no-shave-1500',
        label: '1500 Grafts',
        price: 15933,
      },
    ],
  },
  {
    id: 'other',
    name: 'Other',
    products: [
      {
        id: 'bundle-max-grafts-lllt',
        label: 'MAX RESULTS: MaxGraft and LLLT',
        price: 18316,
      },
      {
        id: 'bundle-max-grafts-laser-cap',
        label: 'MAX RESULTS: MaxGraft and Laser Cap',
        price: 18316,
        taxable: 2000, // Only the Laser Cap product is taxable
      },
      {
        id: 'lllt-max-results',
        label: 'LLLT Max Results',
        price: 3240,
      },
      {
        id: 'laser-cap',
        label: 'Laser Cap',
        price: 2499,
        taxable: 'all',
      },
      {
        id: 'genetic-testing',
        label: 'Genetic Testing',
        price: 0,
      },
      {
        id: 'rx-compound',
        label: 'Rx Compound',
        price: 499,
      },
    ],
  },
];

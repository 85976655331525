import React, { useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { QuoteContext } from '.';
import { LENDERS } from '../constant/lenders';
import { USNumberFormat } from '../utils';
import { STATES } from '../constant/states';

export function SelctionsSection() {
  const { active, setActive } = useContext(QuoteContext);

  const onChangeHandler = (value, fieldName) => {
    switch (fieldName) {
      case 'state':
        if (value === STATES[0].name) return;
        setActive({
          ...active,
          state: STATES.find((state) => state.name === value),
        });
        localStorage.setItem('AthensStateName', value);
        break;
      case 'lender':
        if (value === LENDERS[0].name) return;
        setActive({
          ...active,
          lender: LENDERS.find((lender) => lender.name === value),
        });
        break;
      case 'downpayment':
        setActive({ ...active, downpayment: value });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedValue = localStorage.getItem('AthensStateName');
    if (storedValue) onChangeHandler(storedValue, 'state');
  }, []);

  const initialValues = {
    lender: active.lender.name,
    downpayment: active.downpayment,
    state: active.state.name,
  };

  return (
    <section
      id='selections-section'
      className='container-fluid py-4 pt-sm-5 print-hide'
    >
      <Formik initialValues={initialValues} enableReinitialize>
        {(formik) => {
          return (
            <Form className='row justify-content-center gy-4 padding-x'>
              <div className='col-auto'>
                <h3
                  className={`h5 pb-1 ${
                    active.state.name === STATES[0].name
                      ? 'athens-text-primary'
                      : 'athens-text-navy'
                  }`}
                >
                  State {active.state.name === STATES[0].name && <sup>*</sup>}
                </h3>
                <div>
                  <Field
                    as='select'
                    id='state'
                    name='state'
                    className={`form-select ${
                      active.state.name === STATES[0].name
                        ? 'warning-border'
                        : ''
                    }`}
                    onChange={(e) => onChangeHandler(e.target.value, 'state')}
                  >
                    {STATES.map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className='col-auto'>
                <h3
                  className={`h5 pb-1 ${
                    active.lender.name === LENDERS[0].name
                      ? 'athens-text-primary'
                      : 'athens-text-navy'
                  }`}
                >
                  Lender{' '}
                  {active.lender.name === LENDERS[0].name && <sup>*</sup>}
                </h3>
                <div>
                  <Field
                    as='select'
                    id='lender'
                    name='lender'
                    className={`form-select ${
                      active.lender.name === LENDERS[0].name
                        ? 'warning-border'
                        : ''
                    }`}
                    onChange={(e) => onChangeHandler(e.target.value, 'lender')}
                  >
                    {LENDERS.map((lender) => (
                      <option key={lender.name} value={lender.name}>
                        {lender.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className='col-auto'>
                <h3 className='h5 pb-1 athens-text-navy'>Down Payment</h3>
                <div>
                  <Field name='downpayment'>
                    {(props) => {
                      const { field } = props;
                      return (
                        <input
                          id='downpayment'
                          type='text'
                          className='form-control'
                          placeholder='0'
                          {...field}
                          onChange={(e) => {
                            const formattedValue = USNumberFormat(
                              e.target.value
                            );
                            formik.setFieldValue('downpayment', formattedValue);
                            onChangeHandler(formattedValue, 'downpayment');
                          }}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}

export default SelctionsSection;
//
